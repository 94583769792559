import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './routes';

function App() {
    return (
        <>
            {/* <Router><Routing /> </Router> */}
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                Creăm un site uimitor. Vă mulțumim că ne-ați vizitat.
            </div>
        </>
    );
}

export default App;
